import React, { useRef } from "react";
import emailjs from 'emailjs-com';
import './Contact2.css';

const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        // EmailJS service
        emailjs.sendForm("service_bdfl0ie", "template_u6wiihh", form.current, 'CLcHWAKSemVMd1_sU')
            .then((result) => {
                console.log(result.text);
                alert('Email sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('Failed to send email. Please try again later.');
            });

        e.target.reset();
    };

    return (
        <>
            <div className="contactform2">
                <div className="contact2-container">
                    <div className="contact2-header contact2-container">
                        <h1>DO YOU NEED URGENT HELP?</h1>
                        <p>Our Mental Health and Addiction Support team is available to assist you.</p>
                    </div>

                    <div className="contact-form-content">
                        <div className="left-side">
                            <div className="address details">
                                <i className="fas fa-map-marker-alt"></i>
                                <div className="topic">Address</div><br />
                                <div className="text-one">4441 Auburn Blvd. Suite P, </div>
                                <div className="text-two">Sacramento CA 95841</div>
                            </div>

                            <div className="phone details">
                                <i className="fas fa-phone-alt"></i>
                                <div className="topic">Phone</div><br />
                                <div className="text-one"><a href="tel:3234195997"> +1 (323) 419-5997</a></div>
                            </div>

                            <div className="email details">
                                <i className="fas fa-envelope"></i>
                                <div className="topic">Email</div><br />
                                <div className="text-one">info@AuburnRehab.biz</div>
                            </div>
                        </div>

                        <div className="right-side">
                            <div className="topic-text">Send us a message</div> <br />
                            <p className="topic-text-p">Please call our admission department if you have any questions.</p>
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="input-box">
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Your Name"
                                        required
                                        pattern="[a-zA-Z\s]+"
                                        title="Name should only contain letters."
                                    />
                                </div>
                                <div className="input-box">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Your Email"
                                        required
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                        title="Enter a valid email address."
                                    />
                                </div>
                                <div className="input-box">
                                    <input
                                        type="tel"
                                        name="phone"
                                        placeholder="Your Phone"
                                        required
                                        pattern="[0-9]{10}"
                                        title="Enter a 10-digit phone number."
                                    />
                                </div>
                                <div className="input-box">
                                    <input
                                        type="text"
                                        name="subject"
                                        placeholder="Subject"
                                        required
                                    />
                                </div>
                                <div className="input-box message-box">
                                    <textarea
                                        name="message"
                                        rows="4"
                                        placeholder="How can we help?"
                                        required
                                    ></textarea>
                                </div>
                                <input className="contact-btn" type="submit" value="Submit" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactForm;
