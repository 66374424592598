import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Link as LinkRoll } from "react-scroll";
import "./Menu3.css";

const Menu3 = () => {
  return (


    <>
    <div className="section-bg">
      <div id="mentalHealthMenucards">
        <div className="mentalHealthMenucardsHeader container">
          <h1>AUBURN REHAB MENTAL HEALTH PROGRAMS</h1>
          <p>At Auburn Rehab Treatment Center, clients receive individual
          therapy alongside a range of additional treatments, including relapse
          prevention, trauma therapy, mindfulness meditation, transportation,
          yoga, and more. We offer support that clients need to allow them to
          gradually go back to treatment.</p>
        </div>



      <div className="mentalHealthMenucardsFlex container">

{/* DEPRESSION */}

      <Fade top>
        <div className="mentalHealthCard">
          <img src='https://sunset-rehab.s3.us-west-1.amazonaws.com/alcohol-addiction.jpg' alt="Mental Health" loading="lazy" />         
            <div className="card-body">
              <h1 className="card-title">Depression</h1>
              <p className="card-caption">Providing compassionate and expert support for those struggling with depression to help restore mental well-being and quality of life.</p>
              <div className="menu-btn">
              <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/depression">
                <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
            </div>
        </div>
      </Fade>

{/* ANXIETY */}


      <Fade bottom>
        <div className="mentalHealthCard">
        <img src='https://sunset-rehab.s3.us-west-1.amazonaws.com/cocaine-addiction.jpg' alt="Anxiety Program" loading="lazy" />            
            <div className="card-body">
              <h1 className="card-title">Anxiety</h1>
              <p className="card-caption">Our Anxiety Program offers personalized, evidence-based support designed to help individuals manage and overcome anxiety through comprehensive therapy and coping strategies.</p>
              <div className="menu-btn">
              <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/anxiety">
                <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
            </div>
        </div>
      </Fade>

{/* PANIC DISORDER  */}

      <Fade top>
        <div className="mentalHealthCard">
        <img src='https://sunset-rehab.s3.us-west-1.amazonaws.com/heroin-addiction.jpg' alt="Mental Health" loading="lazy" />
            <div className="card-body">
              <h1 className="card-title">Panic Disorder</h1>
              <p className="card-caption">Our Panic Disorder program offers comprehensive, evidence-based treatment to help individuals manage and overcome panic attacks and related anxiety symptoms effectively.</p>
              <div className="menu-btn">
              <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/panice-disorder">
                <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
            </div>
        </div>
      </Fade>

{/* ACUTE STRESS DISORDER */}

      <Fade top>
        <div className="mentalHealthCard">
        <img src='https://sunset-rehab.s3.us-west-1.amazonaws.com/fentanyl-addiction.jpg' alt="Mental Health" loading="lazy" />
            <div className="card-body">
              <h1 className="card-title">Acute Stress Disorder</h1>
              <p className="card-caption">Our Acute Stress Disorder program offers comprehensive support to individuals experiencing severe stress reactions, providing tailored interventions to stabilize symptoms and foster recovery.</p>
              <div className="menu-btn">
              <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/acute-stress-disorder">
                <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
            </div>
        </div>
      </Fade>


{/* ADHD */}

<Fade top>
        <div className="mentalHealthCard">
        <img src='https://20thstreetrecovery.s3.us-west-1.amazonaws.com/20th_street_medias/collage1.jpg' alt="Mental Health" loading="lazy" />
            <div className="card-body">
              <h1 className="card-title">ADHD</h1>
              <p className="card-caption">Our ADHD program offers personalized support and strategies to help individuals manage symptoms and achieve their goals effectively.</p>
              <div className="menu-btn">
              <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/adhd">
                <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
            </div>
        </div>
      </Fade>


{/* ANGER DISORDER */}

<Fade top>
        <div className="mentalHealthCard">
        <img src='https://sunset-rehab.s3.us-west-1.amazonaws.com/meth-addiction.jpg' alt="Mental Health" loading="lazy" />
            <div className="card-body">
              <h1 className="card-title">Anger Disorder</h1>
              <p className="card-caption">Our Anger Disorder program offers specialized therapy to help individuals manage and overcome their anger issues effectively.</p>
              <div className="menu-btn">
              <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/anger-disorder">
                <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
            </div>
        </div>
      </Fade>

{/* BIPOLAR DISORDER */}

<Fade top>
        <div className="mentalHealthCard">
        <img src='https://sunset-rehab.s3.us-west-1.amazonaws.com/xanax-addiction.jpg' alt="Mental Health" loading="lazy" />
            <div className="card-body">
              <h1 className="card-title">Bipolar Disorder</h1>
              <p className="card-caption">Our Anger Disorder program offers specialized therapy to help individuals manage and overcome their anger issues effectively.</p>
              <div className="menu-btn">
              <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/bipoalar-disorder">
                <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
            </div>
        </div>
      </Fade>

{/* PTSD */}

<Fade top>
        <div className="mentalHealthCard">
        <img src='https://20thstreetrecovery.s3.us-west-1.amazonaws.com/20th_street_medias/collage2.jpg' alt="Mental Health" loading="lazy" />
            <div className="card-body">
              <h1 className="card-title">PTSD</h1>
              <p className="card-caption">Reclaim Your Life: Our PTSD Program offers compassionate care and evidence-based therapies to help you overcome trauma and build a brighter future. Begin your journey to healing today.</p>
              <div className="menu-btn">
              <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/ptsd">
                <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
            </div>
        </div>
      </Fade>
      

{/* SCHIZOPHRENIA */}

<Fade top>
        <div className="mentalHealthCard">
        <img src='https://sunset-rehab.s3.us-west-1.amazonaws.com/mdma-addiction.jpg' alt="Mental Health" loading="lazy" />
            <div className="card-body">
              <h1 className="card-title">Schizophrenia</h1>
              <p className="card-caption">Our dedicated Schizophrenia Program is designed to provide comprehensive care, tailored therapies, and unwavering support to help patients manage their symptoms and lead fulfilling lives.</p>
              <div className="menu-btn">
              <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/schizophrenia">
                <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
            </div>
        </div>
      </Fade>    


{/* SCHIZOAFFECTIVE */}

<Fade top>
        <div className="mentalHealthCard">
        <img src='https://sunset-rehab.s3.us-west-1.amazonaws.com/oxycodone-addiction.jpg' alt="Mental Health" loading="lazy" />
            <div className="card-body">
              <h1 className="card-title">Schizoaffective</h1>
              <p className="card-caption">Schizoaffective disorder is a complex mental health condition characterized by a combination of symptoms of schizophrenia, such as hallucinations or delusions, and mood disorder symptoms, such as depression or mania.</p>
              <div className="menu-btn">
              <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/Schizoaffective">
                <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
            </div>
        </div>
      </Fade>   

      </div>
    </div>
  </div>










    {/* 


      <div class="menu3-wrap container">






        <Fade top>
        <div className="menu3-card">
            <img src='https://sunset-rehab.s3.us-west-1.amazonaws.com/kratom-addiction.jpg' alt="Mental Health" loading="lazy" />
          <div className="menu3-card-caption">
            <h1>ADHD​</h1>
            <div className="card-btn">
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/adhd">
                  <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
          </div>
        </div>
        </Fade>


        <Fade bottom>
        <div className="menu3-card">
            <img src='https://rodeo-recovery-substance-abuse.s3.us-west-1.amazonaws.com/opiate.jpg' alt="Mental Health" loading="lazy" />
          <div className="menu3-card-caption">
            <h1>PTSD​</h1>
            <div className="card-btn">
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/ptsd">
                  <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
          </div>
        </div>
        </Fade>


        <Fade top>
        <div className="menu3-card">
            <img src='https://sunset-rehab.s3.us-west-1.amazonaws.com/mdma-addiction.jpg' alt="Mental Health" loading="lazy" />
          <div className="menu3-card-caption">
            <h1>Bipolar Disorder​</h1>
            <div className="card-btn">
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/bipolar-disorder">
                  <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
          </div>
        </div>
        </Fade>


        <Fade bottom>
        <div className="menu3-card">
            <img src='https://sunset-rehab.s3.us-west-1.amazonaws.com/meth-addiction.jpg' alt="Mental Health" loading="lazy" />
          <div className="menu3-card-caption">
            <h1>Schizoaffective​</h1>
            <div className="card-btn">
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/schizoaffective">
                  <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
          </div>
        </div>
        </Fade>


        <Fade top>
        <div className="menu3-card">
            <img src='https://rodeo-recovery-substance-abuse.s3.us-west-1.amazonaws.com/opioid.jpg' alt="Mental Health" loading="lazy" />
          <div className="menu3-card-caption">
            <h1>Schizophrenia​</h1>
            <div className="card-btn">
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/schizophrenia">
                  <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
          </div>
        </div>
        </Fade>


      </div>
      </div> */}


    </>
  );
};

export default Menu3;
