import React from 'react';
import './ImageGrid.css';
import DepressedMan from '../../assets/depressed_man.webp'
import Depression from '../../assets/depression.webp';
import Bipolar from '../../assets/bipolar.webp';
import Anxiety from '../../assets/anxiety.webp';
import PTSD from '../../assets/PTSD.webp';
import ADHD from '../../assets/ADHD.webp';
import SCHIZOPHRENIA from '../../assets/schizophrenia.webp';
import Rain from '../../assets/rain.webp';

import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";



const ImageGrid = () => {
  return (


<>
 <div className='sectionBg'>

            <div className='imageGridHeader container'>
                <h1>Mental Health Conditions We Treat</h1>
                <p>Our expert team addresses issues including depression, anxiety, bipolar disorder, and substance dependencies to support individuals on their path to recovery.</p>
                <div className='gridHeaderBtn hide'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/mental-health">
                            <button>Learn More</button>
                        </Link>
                    </LinkRoll>
                </div>            
                </div>


        <div className='imageGrid'>

{/* ----------- LEFT ----------- */}

            <div className='gridLeft hideMe'>

                <div className='gridItem'>
                <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/panic-disorder">
                            <div className="gridLeftImage1 gridImage">
                                    <img src={Depression} alt="ADHD" />
                                <div className="gridImageCaption">
                                        <h1>Depression</h1>
                                </div>
                            </div>    
                        </Link>
                    </LinkRoll>
                </div>

                <div className='gridItem2 '>
                <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/anxiety">
                            <div className="gridLeftImage2 gridImage">
                                    <img src={Anxiety} alt="ADHD" />
                                <div className="gridImageCaption2">
                                        <h1>Depression</h1>
                                </div>
                            </div>    
                        </Link>
                    </LinkRoll> 
                </div>

                <div className='gridItem3'>
                <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/bipolar-disorder">
                            <div className="gridLeftImage3 gridImage">
                                    <img src={Bipolar} alt="Bipolar" />
                                <div className="gridImageCaption3">
                                        <h1>Bipolar</h1>
                                </div>
                            </div>    
                        </Link>
                    </LinkRoll>  
                </div>

            </div>

{/* ----------- END OF LEFT ----------- */}




{/* ----------- CENTER ----------- */}

            <div className='gridCenter'>
                <img src={Rain} alt='rain' loading='lazy' />
                <img src={DepressedMan} alt='Depressed Man' loading='lazy' /> 
                <div className='gridCenterBtn hide'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/mental-health">
                            <button>Learn More</button>
                        </Link>
                    </LinkRoll>
                </div> 
            </div>

{/* ----------- END OF CENTER ----------- */}



{/* ----------- RIGHT ----------- */}

            <div className='gridRight hideMe'>

                    <div className='gridItem'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/panic-disorder">
                            <div className="gridRightImage1 gridImage">
                                    <img src={PTSD} alt="ADHD" />
                                <div className="gridImageCaption4">
                                        <h1>Panic Disorder</h1>
                                </div>
                            </div>    
                        </Link>
                    </LinkRoll>
                    </div>


                <div className='gridItem'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/adhd">
                            <div className="gridRightImage2 gridImage">
                                    <img src={ADHD} alt="ADHD" />
                                <div className="gridImageCaption5">
                                        <h1>ADHD</h1>
                                </div>
                            </div>    
                        </Link>
                    </LinkRoll>
                </div>


                <div className='gridItem'>

                <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/schizophrenia">
                            <div className="gridRightImage3 gridImage">
                                    <img src={SCHIZOPHRENIA} alt="ADHD" />
                                <div className="gridImageCaption6">
                                        <h1>Schizophrenia</h1>
                                </div>
                            </div>    
                        </Link>
                    </LinkRoll>
 
                    </div>            
                </div>

{/* ----------- END OF RIGHT ----------- */}

            
        </div>
    </div>        
</>



  );
};

export default ImageGrid;
