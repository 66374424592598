import React from "react";
import "./Topbar.css";
import { IoLocationSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { AiOutlinePhone } from 'react-icons/ai';


const Topbar = () => {
  return (
    <div className="topbar" name="top">
      <div className="topbar-content">
        <div className="address bar bar1 barHide">
          <p className="phoneNumber"><span><IoLocationSharp />
          </span> 4441 Auburn Blvd. Suite P, Sacramento CA 95841</p>
        </div>

        <div className="email bar bar-box2 barHide">
          <p className="phoneNumber"><span><MdEmail />
          </span> info@AuburnRehab.com</p>
        </div>

        <div className="number bar bar3">
        <p className="phoneNumber"><span><AiOutlinePhone /></span><a href="tel:3234195997"> +1 (323) 419-5997</a></p>        </div>
      </div>
    </div>
  );
};

export default Topbar;
