import React from 'react'
import InsuranceSection from '../insurance/InsuranceSection';
import ContactForm2 from '../contact/Contact2';
import MissionHome from '../mission/MissionHome';
import TherapySA from '../therapySA/TherapySA';
// import Section2 from '../section2/Section2';
import Section3 from '../section3/Section3';
import Cards from '../servicesCards/Cards';
import ImageGrid from '../ImageGrid/ImageGrid';






const home = () => {
  return (
    <>
    
    <Cards /> 
    <ImageGrid />
    <TherapySA />
    {/* <Section2 /> */}

    <InsuranceSection />
    <Section3 />
    <MissionHome />
    <ContactForm2 />
    </>
  )
}

export default home
