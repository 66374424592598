import React from 'react';
import './Hero.css';

import { Link as LinkRoll } from "react-scroll";
import { Link } from "react-router-dom";


import { ReactComponent as SquareCheck } from '../fonts/square-check.svg';


const Hero = () => {
  return (
    <div className='hero-bg'>
      <div className="landing-page">
        <div className="text-container">
          <h1>Auburn<span>Rehab</span></h1>
          <h2>Compasionate Care for Your Complete Mental Well-being</h2>
          <p>Your Path to Wellness Begins Here. Experience compassionate, personalized care designed to support your journey to complete mental well-being.</p>
          <ul>
        <li><SquareCheck /> Support Group</li>            
        <li><SquareCheck /> Evidence-based</li>        
        <li><SquareCheck /> Compassionate Care</li>          
        </ul>

          <div className='heroBtn'>
            <div className='heroBtn1'>
              <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/contact">
                  <button>Call Us</button>
                </Link>
              </LinkRoll>

            </div>
            <div className='heroBtn2'>
            <LinkRoll activeClass="active" to="top" spy={true}smooth={true} duration={500}>
                <Link to="/treatment">
                  <button>Learn More</button>
                </Link>
              </LinkRoll>
            </div>
        </div>

        </div>
        <div className="image-container">
          {/* <img src={DepressedMan} alt="Depressed man" /> */}
        </div>


      </div>
    </div>
  );
}

export default Hero;
