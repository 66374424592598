import React, { useRef } from "react";
import { BsFillPinMapFill } from 'react-icons/bs';
import { AiOutlinePhone } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        // EmailJS service
        emailjs.sendForm("service_bdfl0ie","template_u6wiihh", form.current, 'CLcHWAKSemVMd1_sU')
            .then((result) => {
                console.log(result.text);
                alert('Email sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('Failed to send email. Please try again later.');
            });

        e.target.reset();
    };

    return (
        <>
            <div className="contactform">
                <div className="contact2-header contact2-container">
                    <h1>DO YOU NEED URGENT HELP?</h1>
                    <p>Our Addiction and Recovery Support team is available to assist you.</p>
                </div>

                <section className="container">
                    <div>
                        <div className="footer_get_touch_inner">
                            <div className="get_form_inner">
                                <div className="get_form_inner_text">
                                    <h3>Get In Touch</h3>
                                    <p><i>We'll keep your contact information strictly confidential.</i></p><br/>
                                </div>
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="inputs">
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Your Name"
                                            required
                                            pattern="[a-zA-Z\s]+"
                                            title="Name should only contain letters."
                                        /><br/>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Your Email"
                                            required
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                            title="Enter a valid email address."
                                        /><br/>
                                        <input
                                            type="tel"
                                            name="phone"
                                            placeholder="Your Phone"
                                            required
                                            pattern="[0-9]{10}"
                                            title="Enter a 10-digit phone number."
                                        /><br/>
                                        <input
                                            type="text"
                                            name="subject"
                                            placeholder="Subject"
                                            required
                                        /><br/>
                                    </div>
                                    <div>
                                        <textarea
                                            name="message"
                                            placeholder="How can we help?"
                                            cols="30"
                                            rows="10"
                                            required
                                        ></textarea>
                                        <input type="submit" value="Submit" />
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="contact-cards-container">
                    <div className="get_form_inner2">
                        <h2>CALL US</h2>
                        <p><AiOutlinePhone /><a href="tel:3234195997"> +1 (323) 419-5997</a></p>
                    </div>

                    <div className="get_form_inner2">
                        <h2>EMAIL</h2>
                        <p><AiOutlineMail /> info@AuburnRehab.com </p><br />
                    </div>

                    <div className="get_form_inner2">
                        <h2>LOCATION</h2>
                        <p><BsFillPinMapFill /> 4441 Auburn Blvd. Suite P, Sacramento CA 95841 </p>
                    </div>
                </div>

                <div className="iframe-container"><iframe width="100%" height="600" title="map" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=4441%20Auburn%20Blvd.%20Suite%20P,%20Sacramento%20CA%2095841+(Auburn%20Rehab)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps systems</a></iframe></div>
            </div>
        </>
    );
}

export default ContactForm;
